import React from 'react'
import portfolioData from '../data/portfolio.json';




const inViewport = (elem) => {
    let allElements = document.getElementsByClassName(elem);
    let windowHeight = window.innerHeight;
    const elems = () => {
        for (let i = 0; i < allElements.length; i++) {  //  loop through the sections
            let viewportOffset = allElements[i].getBoundingClientRect();  //  returns the size of an element and its position relative to the viewport
            let top = viewportOffset.top;  //  get the offset top
            if (top < windowHeight) {  //  if the top offset is less than the window height
                allElements[i].classList.add('inview');  //  add the class
            } else {
                allElements[i].classList.remove('inview');  //  remove the class
            }
        }
    }
    elems();
    window.addEventListener('scroll', elems);
}
inViewport('project__item');  //  run the function on all section elements



const Portfolio = () => {


    const renderPortfolio = (portfolio) => {
        return (
            <div className="project">
                {
                    portfolio.map((port, idx) => {
                        return (
                            <div className={'project__item project__item--' + port.caption} key={idx}>
                                <div className="project__container">
                                    <div className="project__content">
                                        {/* <h3 className="project__caption">{port.caption}</h3> */}
                                        <h3 className="project__title">{port.title}</h3>
                                        <h4 className="project__tech">{port.tech}</h4>
                                        {/* <p className="project__company">{port.company}</p> */}
                                        <p className="project__txt">{port.description}</p>
                                        {/* <button
                                            className="project__btn"
                                            rel='noreferrer'
                                            onClick={() => window.open(port.url)}
                                        >View Live</button> */}
                                    </div>
                                    <div className='project__gallery'>
                                        <img
                                            src={port.img}
                                            className="project__img project__img--main"
                                            alt={port.title} />
                                        <img
                                            src={port.img2}
                                            className="project__img"
                                            alt={port.title} />
                                        <img
                                            src={port.img3}
                                            className="project__img"
                                            alt={port.title} />
                                    </div>

                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }


    return (

        <div className='portfolio'>
            <h1 className='portfolio__title'> Projects</h1>
            {renderPortfolio(portfolioData.portfolio)}
        </div>

    )
}

export default Portfolio